import {
  faArrowAltCircleLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";

import InformazioniAmministrazione from "../components/InformazioniAmministrazione";
import NoteAmministrazioneList from "../components/DettagliNote/NoteAmministrazioneList";
import GestisciOperazioni from "../components/GestisciOperazioni";
import authService from "../../services/authService";
import Loader from "react-loader-spinner";

const DettagliAmministrazione = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [activeItem, setActiveItem] = useState("informazioni");
  const token = authService.getCurrentToken();
  const [infoAmministrazione, setInfoAmministrazione] = useState();
  const ref_amministrazione = useParams().ref_amministrazione;
  const history = useHistory();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const eliminaAmministrazione = async function () {
    try {
      await sendRequest(
        process.env.REACT_APP_BASE_URL +
          `/amministrazioni/eliminaAmministrazione/${ref_amministrazione}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Amministrazione eliminata correttamente!",
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: error,
      });
    }
  };

  useEffect(() => {
    const ottieniInfo = async () => {
      try {
        const info = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/amministrazioni/infoAmministrazione/${ref_amministrazione}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setInfoAmministrazione(info);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
          timer: 2000,
        }).then(() => {});
      }
    };
    ottieniInfo();
  }, [ref_amministrazione, sendRequest, token]);

  return (
    <React.Fragment>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && infoAmministrazione && (
        <div className="card my-2 mx-3">
          <div>
            <div
              className="card-header border-0 flex d-flex justify-content-between"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <span style={{ fontWeight: 600, fontSize: "120%" }}>
                {infoAmministrazione.informazioni.denominazione}
              </span>
              {authService.getCurrentRuolo() === "amministratore" && (
                <span
                  role="button"
                  style={{ fontWeight: 600 }}
                  onClick={eliminaAmministrazione}
                >
                  <FontAwesomeIcon icon={faTrash} /> Elimina
                </span>
              )}
            </div>

            <div className="card-body">
              <div>
                <span
                  role="button"
                  style={{ fontWeight: 600 }}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro
                </span>
              </div>
              <Menu tabular stackable>
                <Menu.Item
                  name="informazioni"
                  active={activeItem === "informazioni"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="note"
                  active={activeItem === "note"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="operazioni"
                  active={activeItem === "operazioni"}
                  onClick={handleItemClick}
                  color="blue"
                />
              </Menu>
              <Segment attached="bottom">
                <div className="row"></div>
                {!isLoading &&
                  infoAmministrazione &&
                  activeItem === "informazioni" && (
                    <InformazioniAmministrazione
                      info={infoAmministrazione.informazioni}
                    />
                  )}
                {!isLoading && infoAmministrazione && activeItem === "note" && (
                  <NoteAmministrazioneList
                    note={infoAmministrazione.note}
                    ref_amministrazione={
                      infoAmministrazione.informazioni.id_amministrazione
                    }
                    setActiveItem={setActiveItem}
                  />
                )}
                {!isLoading &&
                  infoAmministrazione &&
                  activeItem === "operazioni" && (
                    <GestisciOperazioni
                      operazioni={infoAmministrazione.operazioni}
                      ref_amministrazione={
                        infoAmministrazione.informazioni.id_amministrazione
                      }
                    />
                  )}
              </Segment>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DettagliAmministrazione;
